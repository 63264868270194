/* .grid {
	pointer-events: none;
	position: fixed;
	width: 110%;
	height: 110%;
	top: -5%;
	left: -5%;
	display: grid;
	grid-template-columns: repeat(50, 2%);
	grid-template-rows: repeat(50, 2%);
} */

.grid__item {
	position: relative;
	opacity: 0.75;
	will-change: transform;
}

.grid__item-img {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	border-radius: 8px;
}

@media (max-width: 1025px) {
	.grid {
		transform: scale(0.8);
		transform-origin: center center;
	}
}

@media (max-width: 768px) {
	.grid {
		transform: scale(0.6);
	}
}

@media (max-width: 480px) {
	.grid {
		transform: scale(0.4);
	}
}

/* Posizioni specifiche */
.pos-1 { grid-area: 10 / 1 / 26 / 7; }
.pos-2 { grid-area: 1 / 18 / 9 / 27; }
.pos-3 { grid-area: 1 / 36 / 14 / 42; }
.pos-4 { grid-area: 13 / 11 / 32 / 18; }
.pos-5 { grid-area: 17 / 32 / 32 / 38; }
.pos-6 { grid-area: 20 / 46 / 28 / 51; }
.pos-7 { grid-area: 43 / 1 / 51 / 10; }
.pos-8 { grid-area: 38 / 14 / 46 / 22; }
.pos-9 { grid-area: 40 / 26 / 51 / 32; }
.pos-10 { grid-area: 37 / 39 / 48 / 47; }

/* If we want to move the inner image */

.grid--img .grid__item {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	will-change: transform;
}

.grid--img .grid__item-img {
	flex: none;
	width: calc(100% + 100px);
	height: calc(100% + 100px);
	will-change: transform;
}


@media (max-width: 1025px) {
	.grid {
		display: none;
	}
}



/* ANIMATIONS */


.text-focus-in {
    -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  
  /* ----------------------------------------------
   * Generated by Animista on 2023-7-8 11:38:50
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * animation text-focus-in
   * ----------------------------------------
   */
  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
  
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
  
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  
  
  
  
  .flip-in-diag-2-tl {
    -webkit-animation: flip-in-diag-2-tl 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.35s both;
    animation: flip-in-diag-2-tl 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.35s both;
  }
  
  @-webkit-keyframes flip-in-diag-2-tl {
    0% {
      -webkit-transform: rotate3d(-1, 1, 0, 80deg);
      transform: rotate3d(-1, 1, 0, 80deg);
      opacity: 0;
    }
  
    100% {
      -webkit-transform: rotate3d(1, 1, 0, 0deg);
      transform: rotate3d(1, 1, 0, 0deg);
      opacity: 1;
    }
  }
  
  @keyframes flip-in-diag-2-tl {
    0% {
      -webkit-transform: rotate3d(-1, 1, 0, 80deg);
      transform: rotate3d(-1, 1, 0, 80deg);
      opacity: 0;
    }
  
    100% {
      -webkit-transform: rotate3d(1, 1, 0, 0deg);
      transform: rotate3d(1, 1, 0, 0deg);
      opacity: 1;
    }
  }
  
  
  .fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  
  
  .slit-in-vertical {
    -webkit-animation: slit-in-vertical 0.45s ease-out both;
    animation: slit-in-vertical 0.45s ease-out both;
  }
  
  
  @-webkit-keyframes slit-in-vertical {
    0% {
      -webkit-transform: translateZ(-800px) rotateY(90deg);
      transform: translateZ(-800px) rotateY(90deg);
      opacity: 0;
    }
  
    54% {
      -webkit-transform: translateZ(-160px) rotateY(87deg);
      transform: translateZ(-160px) rotateY(87deg);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: translateZ(0) rotateY(0);
      transform: translateZ(0) rotateY(0);
    }
  }
  
  @keyframes slit-in-vertical {
    0% {
      -webkit-transform: translateZ(-800px) rotateY(90deg);
      transform: translateZ(-800px) rotateY(90deg);
      opacity: 0;
    }
  
    54% {
      -webkit-transform: translateZ(-160px) rotateY(87deg);
      transform: translateZ(-160px) rotateY(87deg);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: translateZ(0) rotateY(0);
      transform: translateZ(0) rotateY(0);
    }
  }
  
  
  .pic:nth-child(1) {
    animation-delay: 0s;
  }
  
  .pic:nth-child(2) {
    animation-delay: 0.05s;
  }
  
  .pic:nth-child(3) {
    animation-delay: 0.10s;
  }
  
  .pic:nth-child(4) {
    animation-delay: 0.15s;
  }
  
  .pic:nth-child(5) {
    animation-delay: 0.20s;
  }
  
  .pic:nth-child(6) {
    animation-delay: 0.25s;
  }
  
  
  /* SLIDE UP OPACITY */
  .slideup-opacity-anim {
    opacity: 0;
    transform: translateY(20px);
    animation-name: slideup-opacity;
    animation-duration: 1.75s;
    animation-fill-mode: forwards;
  }
  
  @keyframes slideup-opacity {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Media queries specifiche per orientamento verticale */
  @media (orientation: portrait) {
    .grid {
      width: 200%;
      left: -50%;
      transform: scale(0.7);
      transform-origin: center center;
    }
  }

  @media (orientation: portrait) and (max-width: 768px) {
    .grid {
      transform: scale(0.5) translate(-25%, -25%);
    }
  }

  @media (orientation: portrait) and (max-width: 480px) {
    .grid {
      transform: scale(0.35) translate(-45%, -45%);
    }
  }

  /* Media queries esistenti per orientamento orizzontale */
  @media (orientation: landscape) {
    .grid {
      transform: scale(0.8);
      transform-origin: center center;
    }
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    .grid {
      transform: scale(0.6);
    }
  }

  